<template>
  <b-modal v-model="showModal" ref="chatModal" @hide="closeChatModal" title="Chat">
    <div class="chat-messages">
      <div v-for="msg in messages" :key="msg.id" :class="{'text-right': msg.isMine}">
        <b-alert :variant="msg.isMine ? 'primary' : 'secondary'" show>
          {{ msg.text }}
        </b-alert>
      </div>
    </div>
    <template>
      <div class="input-container">
        <b-form-input
          ref="inputBox"
          class="transparent-input"
          :style="{ fontSize: fontSize + 'px' }"
          v-model="userInput"
          @input="adjustFontSize"
        />
      </div>
    </template>
    <template v-slot:modal-footer>
      <b-input-group>
        <b-form-input v-model="userInput" @keyup.enter="sendMessage" placeholder="Ask me anything..." class="mr-2"></b-form-input>
        <b-input-group-append>
          <b-button variant="primary" @click="sendMessage">Send</b-button>
        </b-input-group-append>
      </b-input-group>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import { serverUri } from "@/config";
import { BModal, BAlert, BInputGroup, BFormInput, BButton } from 'bootstrap-vue';

export default {
  components: {
    BModal,
    BAlert,
    BInputGroup,
    BFormInput,
    BButton
  },
  data() {
    return {
      showModal: false,
      userInput: '',
      messages: [],
      datasetInformation: '',
      userInput: '',
      fontSize: 50,
    };
  },
  props: {
    chatData: Object,
    
  },
  methods: {
    openChatModal(chatData) {
      this.messages = []; // Clear existing messages
      console.log(chatData);

      if (chatData) {
        this.datasetInformation = JSON.stringify(chatData);
      }

      try {
        axios
          .post(`${serverUri}/settlement/startchat`, this.datasetInformation)
          .then((response) => {
            this.messages.push({ text: response.data.message, isMine: false });
            this.showModal = true;
          })
          .catch((error) => {
            console.error("Error opening chat modal:", error);
          });
      } catch (error) {
        console.error("Error in try block:", error);
      }
    },
    
    closeChatModal() {
      
      //this.datasetInformation = '';
      this.showModal = false;
    },
     adjustFontSize() {
      // Calculate the new font size based on input length
      const inputLength = this.userInput.length;
      const maxFontSize = 60;
      const minFontSize = 16;
      const scaleFactor = (maxFontSize - minFontSize) / 100; // Adjust as needed

      this.fontSize = maxFontSize - scaleFactor * inputLength;
    },
    /*sendInitialDatasetInfo(chatData) {
      // Send an initial message to ChatGPT with the chatData
      // For example:
      this.datasetInformation = JSON.stringify(chatData);

      if(this.datasetInformation) {
        axios.post(`${serverUri}/settlement/startchat`,
        this.datasetInformation)
          .then(response => {
            this.messages.push({ text: response.data.message, isMine: false });
          })
      }
      else {
        console.log("No dataset information");
      }
    },*/
    sendMessage() {
      const message = this.userInput.trim();
      if (message) {
        this.messages.push({ text: message, isMine: true });
        this.userInput = '';
        console.log("now you can run the gpt connection");
        axios.post(`${serverUri}/settlement/chatbot`, { message, datasetInformation: this.datasetInformation })
          .then(response => {
            this.messages.push({ text: response.data.message, isMine: false, id: response.data.id });
            console.log(this.messages)
            console.log(response)
            
          })
          .catch(error => {
            console.error('Error sending message to backend:', error);
            // Handle error
          });
      }
    },
    mounted() {
      if (this.showModal) {
        this.openChatModal(this.chatData);
      }
    },
  }
};
</script>



<style scoped>
.chat-messages {
  height: 300px; /* Adjust the height as needed */
  overflow-y: scroll;
  overflow-x: hidden; /* Hide horizontal scroll */
}

.b-alert {
  display: inline-block;
  max-width: 80%; /* Adjust the maximum width as needed */
  word-wrap: break-word; /* Wrap text if it overflows */
}

.input-container {
  position: relative;
  width: 300px; /* Adjust width as needed */
}

.transparent-input {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1); /* Almost transparent background */
  color: black; /* Text color */
  font-family: Arial, sans-serif;
  outline: none;
  caret-color: black;
}
</style>

