<template>
    <table>
        <tr>
            <th v-for="(val,key) in data[0]" :key="key">
                {{ key }}
            </th>
        </tr>
        <tr v-for="(item, index) in data" :key="index">
            <td v-for="(val, name, idx) in item" :key="idx">
                {{ val }}
            </td>
        </tr>
    </table>
</template>

<script>
    
export default {
    props: {
        data: Array,
    },
}

</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: center;
  padding: 1px;
  border: 1px solid #ddd;
  font-size: 10px;
}

th {
  background-color: #4472c4;
  color: white;
}

h5 {
    text-align: center;
}

span {
    font-weight: bold;
}
</style>