<template>
  <div class="container mt-1">
    <b-row class="match-height">
        <b-col md="12">
            <b-card>
                <div class="row mb-1">
                
                    <div class="mr-1"><b-form-checkbox v-model="isFuelData" checked="false" name="Switch to fuel" switch>
                    Show Fuel Data
                    </b-form-checkbox></div>
                    <div class="mr-1"><v-select v-model="filters.week" :options="weekOptions" placeholder="Select Week"></v-select> </div>    
                    <div class="mr-1"><v-select v-model="filters.tractor" :options="tractorOptions" placeholder="Select Tractor"></v-select></div>
                    <div class="mr-1"><v-select v-model="filters.driver" :options="driverOptions" :disabled="isFuelData" placeholder="Select Driver"></v-select></div>
                    <div><v-select v-model="filters.year" :options="yearOptions" placeholder="Select Year"></v-select></div>
                
                </div>
                <div class="chart-container">
                    <apexchart
                        v-if="isChartDataLoaded"
                        type="bar"
                        :options="chartOptions"
                        :series="chartSeries"
                    />
                    <!-- Placeholder text when chart data is not loaded -->
                    <div v-else class="text-center py-5">NOTHING TO LOAD</div>
                </div>
            </b-card>
        </b-col>
    </b-row>
            
  </div>
</template>
<script>

//import axios from 'axios';
import http from "@/services/http";
import {serverUri} from '@/config'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import { BFormCheckbox, BCard, BCardBody, BRow, BCol } from 'bootstrap-vue'


export default {
  components: {
    vSelect,
    apexchart: VueApexCharts,
    BFormCheckbox,
    BCard,
    BCardBody,
    BRow,
    BCol,
  
  },
  data() {
    
    return {
        filters: {
            year: 2024,
            week: 1,
            tractor: "",
            driver: "",  
        },
        yearOptions: [2023, 2024], 
        weekOptions: Array.from({ length: 52 }, (v, i) => i + 1),
        tractorOptions: [],
        driverOptions: [],
        chartOptions: {},
        chartSeries: [],
        isChartDataLoaded: false,
        isFuelData: false,  
    };
  },

  async mounted() {

    try {
      const { data } = await http.get(`${serverUri}/settlement/getfilters`);
      this.tractorOptions = data.tractors;
      this.driverOptions = data.drivers;
      this.fetchChartData();
    } catch (error) {
      console.error('Could not fetch filter data:', error);
    }
  },

  methods: {
        
        async fetchChartData() {
            
            try {
                const endpoint = this.isFuelData ? '/settlement/fueldata' : '/settlement/revenue';
                const response = await http.get(`${serverUri}${endpoint}`, { 
                    
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.userData).accessToken}`,
                        'Content-Type': 'application/json'
                    },
                
                    params: this.filters 
                    });
                
                this.chartData = response.data;
                this.initializeChartOptions();
                this.calculateChartData();
                
                
            } catch (error) {
                console.error('Could not fetch chart data:', error);
                this.isChartDataLoaded = false;
            }
        },
        initializeChartOptions() {
            this.chartOptions = {
                chart: {
                    type: 'bar',
                    height: 350,
                    // Set stacked based on your default preference or dynamic conditions
                    
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                            customIcons: []
                        },
                    },
                    stacked: false,
                    zoom: {
                        enabled: false
                    }
                },
                plotOptions: {
                    bar: {
                    horizontal: false, 
                    columnwidth: '60%',  
                    },
                    borderRadius: 10,
                    dataLabels: {
                        enabled: true,
                        maxItems: 14,
                        position: 'top', // top, center, bottom
                    },
                },
                xaxis: {
                    categories: [],
                    type: "datetime",
                    labels:{
                        
                        formatter: function (val) {
                            return new Date(val).toLocaleDateString();
                        },
                    },
                    colors: ["#8217ed", "#8217ed"],
                },
                stroke: {
            
                    show: true,
                    width: 1,
                    colors: ['#8217ed']
                },
                yaxis: {
                    title: {
                    text: 'Revenue',
                    },
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    // Set default show state for the legend
                    show: true,
                },
                dataLabels: {
                    enabled: false,
                    formatter: function (val) {
                    return "$" + val;
                    },
                    
                    style: {
                        fontSize: '12px',
                        colors: ['#8217ed']
                    }
                }
                
            };
        },
        updateChartOptions(aggregatedData) {
            console.log("starting updateChartOptions");
            // Determine the number of unique dates
            console.log("the aggregated data is ", aggregatedData);
            const uniqueDates = Object.keys(aggregatedData).sort((a, b) => new Date(a) - new Date(b));
            console.log("The unique dates for revenue data are ", uniqueDates);
            console.log(Object.values(aggregatedData).sort((a, b) => new Date(a) - new Date(b)));
            const isLessThan14Days = uniqueDates.length < 14;
            console.log("the amount of periods less than 14 ?", isLessThan14Days);


            
            // Format x-axis labels if there are less than 14 days
            /*this.chartOptions.xaxis.labels.formatter = (value, timestamp) => {
                const date = new Date(timestamp);
                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                
                if (this.isFuelData && !this.filters.week){
                    console.log("inside the formatter where is fuel data and no week is selected")
                    return `Week ${value} ${timestamp}`
                }
                else {
                    
                    return `${months[date.getMonth()]} ${date.getDate()}`;
                }
            };*/
            

            // Show or hide data labels inside the bars based on the number of days
            this.chartOptions.plotOptions.dataLabels.enabled = Boolean(isLessThan14Days);

            // Show or hide the legend based on whether the chart is stacked
            this.chartOptions.legend.show = this.chartOptions.chart.stacked && (this.filters.tractor || this.filters.driver);

            if (this.isFuelData) {
                console.log(Object.values(aggregatedData));
                
                // Check if aggregated by week or day
                const aggregatedBy = (this.filters.year && !this.filters.week) ? 'byWeek' : 'byDay';

                console.log("categories for fuel data", Object.keys(aggregatedData[aggregatedBy]));

                this.chartOptions.xaxis = {
                    type: aggregatedBy === 'byWeek' ? 'category' : 'datetime',
                    categories: Object.values(aggregatedData[aggregatedBy]),

                };
                this.chartOptions.yaxis.title.text = 'Fuel Purchased ($)';
                // Set x-axis categories based on aggregation
                /*this.chartOptions.xaxis.categories = Object.keys(aggregatedData[aggregatedBy])
                    .sort((a, b) => {
                        if (aggregatedBy === 'byWeek') {
                        // If sorting by week, convert the week numbers to integers
                        return parseInt(a) - parseInt(b);
                        } else {
                        // If sorting by day, convert the date strings to Date objects
                        return new Date(a) - new Date(b);
                        }
                    })
                    .map(key => {
                        if (aggregatedBy === 'byWeek') {
                        // If aggregated by week, return a label for the week
                        return `${key}`;
                        } else {
                        // If aggregated by day, return the date
                        console.log("Aggregated by day in fuel Data", key);
                        return new Date(key).toLocaleDateString();
                        }
                    });*/
                
                return;
            }

            //this.chartOptions.xaxis.categories = uniqueDates.map(date => new Date(date).getTime());
  

            if(!this.isFuelData){

                this.chartOptions.xaxis = {
                    type: 'datetime',
                    categories: uniqueDates,

                };

                if (this.filters.tractor && this.filters.driver) {
                    // Options for grouped chart
                    this.chartOptions.plotOptions = {
                        bar: {
                            horizontal: false,
                            dataLabels: {
                                position: 'top'
                            },
                        }
                    };
                
                } 
                else if ((this.filters.tractor || this.filters.driver) && this.filters.week && !this.filters.year) {   
                }
                else if ((this.filters.tractor || this.filters.driver) && this.filters.year && !this.filters.week) {
                }
            }
            

        },
        prepareSeriesData(aggregatedData) {
            console.log("started prepareSeriesData");
            let seriesData = [];
            
            if (this.filters.tractor && this.filters.driver) {
                console.log("tractor and driver selected")
                // Logic for multiple series when both tractor and driver filters are selected
                let tractorSeries = { name: `Tractor ${this.filters.tractor}`, data: [] };
                let driverSeries = { name: `Driver ${this.filters.driver}`, data: [] };

                Object.entries(aggregatedData).forEach(([date, data]) => {
                    if (data.byTractor[this.filters.tractor]) {
                    tractorSeries.data.push({
                        x: new Date(date).getTime(),
                        y: data.byTractor[this.filters.tractor]
                    });
                    }

                    if (data.byDriver[this.filters.driver]) {
                    driverSeries.data.push({
                        x: new Date(date).getTime(),
                        y: data.byDriver[this.filters.driver]
                    });
                    }
                });
                console.log(tractorSeries, driverSeries);
                seriesData = [tractorSeries, driverSeries];    
            }
            /*else if ((this.filters.tractor || this.filters.driver) && this.filters.week) {
                // Initialize an object to hold the data for each tractor or driver across the specified week
                let dataByEntity = {}; // 'Entity' could be either 'tractor' or 'driver'

                // Determine whether we are filtering by tractor or driver
                const filterType = this.filters.tractor ? 'tractor' : 'driver';
                
                Object.entries(aggregatedData).forEach(([date, data]) => {
                // Assuming you have a way to determine if the data belongs to the specified week
                // You might need to adjust this to fit how your data is structured
                if (this.isDataInSpecifiedWeek(date, this.filters.week)) {
                    if (filterType === 'tractor') {
                    Object.keys(data.byTractor).forEach(tractorId => {
                        if (!dataByEntity[tractorId]) {
                        dataByEntity[tractorId] = [];
                        }
                        dataByEntity[tractorId].push(data.byTractor[tractorId]);
                    });
                    } else { // filterType === 'driver'
                    Object.keys(data.byDriver).forEach(driverId => {
                        if (!dataByEntity[driverId]) {
                        dataByEntity[driverId] = [];
                        }
                        dataByEntity[driverId].push(data.byDriver[driverId]);
                    });
                    }
                }
                });

                // Convert the aggregated data by entity into the series format
                seriesData = Object.entries(dataByEntity).map(([entityId, revenueData]) => ({
                name: filterType === 'tractor' ? `Tractor ${entityId}` : `Driver ${entityId}`,
                data: revenueData
                }));
            }*/

            
            // Logic for a stacked chart when only the tractor filter is selected and week is specified
            else if (this.filters.tractor && !this.filters.driver && this.filters.week) {
                console.log("tractor and week selected but not driver")
                let tractorSeries = {};
                // Iterate through each date and create a series for each tractor
                Object.entries(aggregatedData).forEach(([date, data]) => {
                Object.keys(data.byTractor).forEach(tractor => {
                    if (!tractorSeries[tractor]) {
                    tractorSeries[tractor] = {
                        name: `Tractor ${tractor}`,
                        data: []
                    };
                    }
                    let dataPoint = {
                    x: new Date(date).getTime(),
                    y: data.byTractor[tractor]
                    };
                    tractorSeries[tractor].data.push(dataPoint);
                });
                });
                console.log(Object.values(tractorSeries));
                seriesData = Object.values(tractorSeries);
            }
            // Logic for a stacked chart when only the driver filter is selected and week is specified
            else if (this.filters.driver && !this.filters.tractor && this.filters.week) {
                console.log("driver and week selected but not tractor")
                let driverSeries = {};
                // Iterate through each date and create a series for each driver
                Object.entries(aggregatedData).forEach(([date, data]) => {
                Object.keys(data.byDriver).forEach(driver => {
                    if (!driverSeries[driver]) {
                    driverSeries[driver] = {
                        name: `Driver ${driver}`,
                        data: []
                    };
                    }
                    let dataPoint = {
                    x: new Date(date).getTime(),
                    y: data.byDriver[driver]
                    };
                    driverSeries[driver].data.push(dataPoint);
                });
                });
                console.log(Object.values(driverSeries));
                seriesData = Object.values(driverSeries);
            }
            else if( !this.filters.driver && !this.filters.tractor && !this.filters.week && this.filters.year){
                let yearlyDataSeries = {name:'Revenue', data:[]}
                Object.entries(aggregatedData).forEach(([date,data]) => {
                    yearlyDataSeries.data.push(data.total);
                    
                });  
                seriesData = [yearlyDataSeries];
            }
            else if( !this.filters.driver && !this.filters.tractor && this.filters.week && this.filters.year){
                let weeklyDataSeries = {name:'Revenue', data:[]}
                Object.entries(aggregatedData).forEach(([date,data]) => {
                    weeklyDataSeries.data.push(data.total);
                });
                seriesData = [weeklyDataSeries];
            }
            
            else {
                
                Object.keys(aggregatedData).forEach(date => {
                let dataPoint = { x: date, y: aggregatedData[date].total };

                if (this.filters.tractor) {
                    dataPoint = { x: date, y: aggregatedData[date].byTractor[this.filters.tractor] || 0 };
                } else if (this.filters.driver) {
                    dataPoint = { x: date, y: aggregatedData[date].byDriver[this.filters.driver] || 0 };
                }
                seriesData.push({
                    name: this.filters.tractor ? `Tractor ${this.filters.tractor}` : this.filters.driver ? `Driver ${this.filters.driver}` : '',
                    data: [dataPoint]
                });
                });

            }
            return seriesData;
        },
        prepareFuelSeriesData(aggregatedData) {
            let seriesData = [];
            if (this.filters.year && !this.filters.week) {
                // Logic for preparing series data aggregated by week
                seriesData = [{
                        name: `Weekly Fuel Expense`,
                        data: Object.values(aggregatedData.byWeek) // Assuming value is the total fuel data for the week
                    }];
                
            } else {
            // Logic for preparing series data aggregated by day
            seriesData = [{
                name: 'Fuel Spend',
                data: Object.values(aggregatedData.byDay)
            }];
            }
            return seriesData;
        },
        isDataInSpecifiedWeek(date, weekNumber) {
            // Implement logic to determine if the date falls within the specified week
            // This is a placeholder function; you'll need to replace it with actual logic based on your date and week handling
            return true; // Placeholder return
        },
        calculateChartData() {
 
            
            let aggregatedData = this.isFuelData ? this.aggregateFuelData() : this.aggregateRevenueData();
            let seriesData = this.isFuelData ? this.prepareFuelSeriesData(aggregatedData) : this.prepareSeriesData(aggregatedData);
            this.updateChartOptions(aggregatedData);
            this.chartSeries = seriesData;
            this.isChartDataLoaded = true;
        },
        aggregateFuelData() {
            // Initialize the structure for fuel data aggregation
            const aggregatedData = {
            byDay: {},
            byWeek: {}
            };

            this.chartData.forEach(entry => {
            const date = entry.date.split('T')[0];
            const value = Math.round(parseFloat(entry.total_purchase_amount));

            // Aggregate by day
            if (!aggregatedData.byDay[date]) {
                aggregatedData.byDay[date] = 0;
            }
            aggregatedData.byDay[date] += value;

            // Aggregate by week if the year is selected but not the week
            if (this.filters.year && !this.filters.week) {
                const weekNum = entry.week_num;
                if (!aggregatedData.byWeek[weekNum]) {
                aggregatedData.byWeek[weekNum] = 0;
                }
                aggregatedData.byWeek[weekNum] += value;
            }
            });
            Math.round(aggregatedData);
            return aggregatedData;
        },
        
        aggregateRevenueData() {
            
                // Initialize an object to store the aggregated data
            const aggregatedData = {};
                // Determine the grouping criteria based on selected filters
            const groupBy = this.filters.tractor ? 'tractor' : this.filters.driver ? 'driver' : 'total';
            // Aggregate data based on grouping criteria
            this.chartData.forEach(entry => {
                const date = entry.date.split('T')[0];
                const value = Math.round(parseFloat(entry.daily_gross_amt));

                if (!aggregatedData[date]) {
                    aggregatedData[date] = {
                    total: 0,
                    byTractor: {},
                    byDriver: {}
                    };
                }
                // Add to total
                aggregatedData[date].total += value;
                // Add to tractor subtotal
                if (entry.vehicle) {
                    if (!aggregatedData[date].byTractor[entry.vehicle]) {
                    aggregatedData[date].byTractor[entry.vehicle] = 0;
                    }
                    aggregatedData[date].byTractor[entry.vehicle] = Math.round(aggregatedData[date].byTractor[entry.vehicle] + value);
                }
                // Add to driver subtotal
                if (entry.driver_1) {
                    if (!aggregatedData[date].byDriver[entry.driver_1]) {
                    aggregatedData[date].byDriver[entry.driver_1] = 0;
                    }
                    aggregatedData[date].byDriver[entry.driver_1] = Math.round(aggregatedData[date].byDriver[entry.driver_1]  +  value);
                }
              
                
            });
            return aggregatedData;  
            
        }
  },

  watch: {
     isFuelData: {
        async handler() {
        await this.fetchChartData();
        },
        
    },
    filters: {
      async handler() {
        await this.fetchChartData();
      },
      deep: true
    }
  },

  
}
</script>
<style scoped>
.chart-container {
  border: 1px solid #ccc;
  min-height: 400px;
}
</style>

