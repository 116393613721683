<template>
    <div>

        <b-tabs pills card>
            <b-tab title="Explore" active>
                <b-row class="match-height">
                    <b-col cols="12">
                        <b-row class="align-items-center">
                            <b-col cols="8" class="text-left">
                                <h3>Settlement Reports</h3>
                            </b-col>
                        
                            <b-col cols="3" class="text-right">
                                
                            </b-col>
                            <b-col cols="1" class="text-left"></b-col>
                        </b-row>
                        

                        <b-card-body>
                            <div>
                                <b-row>
                                    <b-col cols="6">
                                        <b-form-group label="Report">
                                            <v-select
                                                v-model="selected"
                                                @input="onChangeTable(selected)"
                                                :dir="
                                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                                "
                                                label="label"
                                                :options="option"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="6">
                                        <!-- sorting  -->
                                        <b-form-group
                                            label-size="sm"
                                            label-align-sm="left"
                                            label-for="sortBySelect"
                                            class="mb-md-0"
                                        >
                                            <b-form-group label="Date Range">
                                                <flat-pickr
                                                    v-model="rangeDate"
                                                    @input="onChangeDate(rangeDate)"
                                                    class="form-control"
                                                    :config="{ mode: 'range' }"
                                                />
                                            </b-form-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <!--<b-col cols="6">
                                        <b-form-group label="Weekly Statement">
                                            <v-select
                                                v-model="selected"
                                                @input="onChangeTable(selected)"
                                                :dir="
                                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                                "
                                                label="label"
                                                :options="option"
                                            />
                                        </b-form-group>
                                    </b-col> -->
                                    <!-- filter -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group
                                            label="Search"
                                        
                                            label-for="filterInput"
                                            
                                        >
                                            <b-input-group>
                                            <b-form-input
                                                id="filterInput"
                                                v-model="filter"
                                                type="search"
                                                placeholder="Type to Search"
                                            />
                                            <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">
                                                Clear
                                                </b-button>
                                            </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>

                            <div class="d-sm-flex mt-1 justify-content-sm-between flex-wrap">
                                
                                
                                
                                <div class="align-items-center mt-2 mt-sm-0 ">
                                    <b-button variant="info" size="sm" @click="exportPDF">
                                    <feather-icon icon="DownloadIcon" class="mr-50" />PDF
                                    </b-button>
                                    <b-button variant="info" size="sm" @click="csvExport" class="ml-1 mr-1">
                                    <feather-icon icon="DownloadIcon" class="mr-50" />CSV
                                    </b-button>
                                    <b-button variant="primary" size="sm" @click="onUpload" :class="{'btn-glow': isGlowing }">
                                        <feather-icon icon="UploadIcon" class="mr-50"/> Upload 
                                    </b-button>
                                </div>
                            </div>

                            
                        
                            <div>
                                <b-table
                                    striped
                                    hover
                                    responsive
                                    class="position-relative"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :items="items"
                                    :fields="fields"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(avatar)="data">
                                        <b-avatar :src="data.value" />
                                    </template>

                                    <!-- <template #cell(qty)="data">
                                        <span>{{ (Math.round(data.item.qty * 10) / 10).toFixed(1) }}</span>
                                    </template> -->

                                    <template #cell(pur_amt)="data">
                                        <span>${{ data.item.pur_amt }}</span>
                                    </template>

                                    <template #cell(repair_misc_amt)="data">
                                        <span>{{ Math.abs(data.item.repair_misc_amt) }}</span>
                                    </template>
                                </b-table>

                                
                            </div>

                        </b-card-body>
                    

                        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                            <!-- page length -->
                            <b-form-group
                                label="Per Page"
                                label-cols="6"
                                label-align="left"
                                label-size="sm"
                                label-for="sortBySelect"
                                class="text-nowrap mb-md-0 mr-1"
                            >
                                <b-form-select
                                    id="perPageSelect"
                                    v-model="perPage"
                                    size="sm"
                                    inline
                                    :options="pageOptions"
                                />
                            </b-form-group> 

                            <!-- pagination -->
                            <div>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    first-number
                                    last-number
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mb-0"
                                >
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div> 
                        </b-card-body>

                        <!-- <revenue-chart/> -->  <!-- IMPORTING THE REVENUE CHART HERE -->
                    </b-tab>
                </b-col>
            </b-row>    
            <b-tab title="Notes">

                <b-card-body>
                    <!-- <b-card-text>
                        <h1>Add Insight</h1>
                            <b-button 
                                variant="success" 
                                @click="toggleChatModal"
                                :disabled="!rangeDate || !selected"
                            >
                                ⚡ Insight
                            </b-button>
                            <chat-modal 
                            ref="chatModal" 
                            :chatData="chatData" 
                            ></chat-modal>
                    </b-card-text> -->
                    <b-row class="match-height">
                        <b-col md="12">
                            <b-card title="Upcoming PM" no-body>
                                <b-card-header>
                                    <b-card-title>Notes</b-card-title>
                                </b-card-header>
                                <hr>
                                <b-card-body>
                                    <b-row>
                                        <b-col md="8">
                                            <div>
                                                <span class="section7-title">Add Notes</span>
                                                <b-form-input
                                                placeholder="Write Note Title..."
                                                v-model="note_title"
                                                />
                                                <br>
                                                <b-form-textarea
                                                id="textarea-default"
                                                placeholder="Write Note..."
                                                v-model="note_details"
                                                rows="6"
                                                />
                                                <br>
                                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" @click="SaveNotes">Save</b-button>
                                            </div>
                                        </b-col>
                                        <b-col md="4" class="note-alert">
                                            <span class="section7-title">Available Notes ({{ this.allnotes.length }}) <span @click=ViewAllNotes() v-if="this.allnotes.length>1" style="float: right;color: #7367f0;cursor:pointer">View All</span> </span>
                                                <b-alert variant="dark" show>
                                                <div class="alert-body" v-if="this.allnotes.length>0">
                                                    <span><strong>{{ this.allnotes[0].title }}</strong> 
                                                    <br> <br>
                                                    {{ this.allnotes[0].notes }}
                                                    <br><br>
                                                    <b-avatar class="mr-1" :src="userData.avatar" />
                                                    {{userData.full_name }} <span class="ml-4">Posted on: {{ dateFormat(this.allnotes[0].date) }}</span>
                                                    </span>
                                                </div>

                                                <div class="alert-body" v-if="this.allnotes.length==0">
                                                No Notes Found
                                                </div>
                                                </b-alert>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-card>
                        </b-col>

                        <b-modal
                        size="lg"
                        id="modal-center"
                        centered
                        title="All Notes"
                        ok-only
                        ok-title="Cancel"
                        v-model="showModal"
                        >
                            <b-row style="height: 500px;overflow: auto;">
                                <b-col cols="11" v-if="this.allnotes.length>1">
                                <div class="alert-body p-2 m-2 notes_list" v-for="(single_note,index) in this.allnotes"  :key="index">
                                    <span><strong>{{ single_note.title }}</strong> 
                                        <br> <br>
                                        {{ single_note.notes }}
                                        <br><br>
                                        <b-avatar class="mr-1" :src="userData.avatar" />
                                        {{userData.full_name }} <span class="ml-4">Posted on: {{ dateFormat(single_note.date) }}</span>
                                    </span>
                                </div>
                                
                                </b-col>
                            </b-row>
                        </b-modal>


                    </b-row>
                    
                </b-card-body>
            </b-tab> 
                

        </b-tabs>
        <div>
        <revenue-chart/> </div> <!-- Setting revenue chart outside of tabs -->
        
        <div class="hiddenContainer">
            <pdfexport ref="contentToExport" forcePageBreak=".page-break" :margin="'0.5cm'" :paper-size="'A4'" :keepTogether="'.prevent-split'" fileName="Settlements">
                <div v-if="items.length">
                    <ExportToPDF 
                        :data="items"
                    />
                </div>
            </pdfexport>
        </div>

   
   


        

    </div>   
    
</template>


<style scoped>
.btn-glow {
  animation: glow-animation 1s 5;
}


@keyframes glow-animation {
  0% { box-shadow: 0 0 5px #7200F0; }
  50% { box-shadow: 0 0 20px #7200F0; }
  100% { box-shadow: 0 0 5px #7200F0; }
}
</style>

<script>

import {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BTable,
    BAvatar,
    BBadge,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormFile,
    BTabs,
    BTab,
    BCardText,
    
    
} from "bootstrap-vue";
import http from "@/services/http";
//import axios from "axios";
import vSelect from "vue-select";
import { serverUri } from "@/config";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ExportToPDF from "./ExportToPDF.vue";
import ChatModal from "./ChatModal.vue"
import { PDFExport } from "@progress/kendo-vue-pdf";
import exportFromJSON from "export-from-json";
import {getUserData} from "@/auth/utils";
import RevenueChart from "./RevenueChart.vue";
import moment from "moment";

export default {
    components: {
        
        BRow,
        BCol,
        BCard,BCardText,BCardBody,
        BFormFile,
        BCardHeader,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,BInputGroupAppend,
        BFormInput,
        BFormTextarea,
        BButton,
        flatPickr,
        ToastificationContent,
        vSelect,
        ExportToPDF,
        ChatModal,
        RevenueChart,
        pdfexport: PDFExport,
        BTabs,BTab,
        ToastificationContent,
        
    },

    data() {
        return {
            //showChatModal: false,
            allnotes : [],
            note_title:'',
            note_details:'',
            userData: getUserData(),
            showModal:false,
            isGlowing: true,
            rangeDate: null,
            perPage: 10,
            pageOptions: [5, 10, 15, 25],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: " ",
            filterOn: [],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            fields: [
                // {
                //     key: "year_num",
                //     sortable: true,
                //     label: "Year",
                // },
                // {
                //     key: "week_num",
                //     sortable: true,
                //     label: "Week",
                // },
                // {
                //     key: "date",
                //     sortable: true,
                //     label: "Date",
                // },
                // {
                //     key: "vehicle",
                //     sortable: true,
                //     label: "Vehicle",
                // },
                // {
                //     key: "truck_stop",
                //     sortable: true,
                //     label: "Truck Stop",
                // },
                // {
                //     key: "city",
                //     sortable: true,
                //     label: "City",
                // },
                // {
                //     key: "state",
                //     sortable: true,
                //     label: "State",
                // },
                // {
                //     key: "qty",
                //     sortable: true,
                //     label: "Quantity",
                // },
                // {
                //     key: "pur_amt",
                //     sortable: true,
                //     label: "Amount",
                // },
            ],
            /* eslint-disable global-require */
            collection: [],
            items: [],
            selected: {
                title: "fuel_purchases",
                label: "Fuel",
            },
            option: [
                {
                    title: "fuel_purchases",
                    label: "Fuel",
                },
                {
                    title: "linehaul_drivers",
                    label: "Drivers",
                },
                {
                    title: "linehaul_trips",
                    label: "Trips",
                },
                {
                    title: "linehaul_spots",
                    label: "Spots",
                },
                {
                    title: "other_settlement_adjustments",
                    label: "Other Settlements",
                },
                {
                    title: "tractor_repairs_misc",
                    label: "Repairs",
                },
            ],
            startDate: null,
            endDate: null,
            sheets: [
                {
                    name: "Sheet1",
                    data: [
                        {
                            c: 2,
                        },
                    ],
                },
            ],
            strKeys: ''
        };
    },
    mounted() {
        setTimeout(() => {
            this.isGlowing = false; // Remove the glow effect after 5 seconds
        }, 5000); // 5000 milliseconds = 5 seconds
        this.AllNotes();
    },
    
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => ({
                    text: f.label,
                    value: f.key,
                }));
        },
    },
    methods: {
        dateFormat(value){
            return moment(value).format('MM/DD/YYYY')
        },
        SaveNotes(){
            if(this.note_title == '')
            {
                this.showToast('danger', 'Warning', 'Please fill note title');

            } else if(this.note_details=='') {

                this.showToast('danger', 'Warning', 'Please fill note details');

            } else {

                http
                .post(`${serverUri}/add_notes`, {
                    user_id:this.userData.id,
                    company_id:this.userData.company_id,
                    title:this.note_title,
                    notes:this.note_details,
                }, 
                {
                    headers: {
                        'Authorization': `Bearer ${getUserData().accessToken}`,
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {

                    this.showToast('success', 'Success', 'Note details has been saved!');
                    this.note_title = '';
                    this.note_details = '';

                    this.AllNotes();
                    
                });
                }
        },
        showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: 'BellIcon',
                    text: text,
                    variant,
                },
            })
        },

        ViewAllNotes(){
            this.showModal = true;
        },

        AllNotes(){
            http
                .post(`${serverUri}/all_notes`, {
                company_id:this.userData.company_id,
                user_id:this.userData.id,
                }, 
                {
                    headers: {
                        'Authorization': `Bearer ${getUserData().accessToken}`,
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {

                if(response.data.data)
                {
                    this.allnotes = response.data.data;

                } else {
                    this.allnotes = [];
                }
                    
                });
        },

        onUpload() {
            this.$router.push({
                name: "apps-settlement-uploading",
            });
        },
        onChange(event) {
            this.file = event.target.files ? event.target.files[0] : null;
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`;
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: 'BellIcon',
                    text: text,
                    variant,
                },
            })
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onChangeTable(table) {
            this.displayTable();
        },
        onChangeDate(date) {
            if (date.split("to")) {
                this.startDate = date.split("to")[0];
                this.endDate = date.split("to")[1];
            } else {
                this.startDate = date;
            }
            
            this.displayTable();
        },
        displayTable() {    
            if (!this.startDate && !this.endDate) {
                this.showToast('warning', 'Please select a date or date range.', 'You must select a date or date range to fetch data.');
                return;
            }          
            this.items = [];
            this.strKeys ='';
            const sumRow = [];            
            let sumQuantity = 0;
            let sumAmount = 0;

            this.setField();
            for (let i = 0; i < this.fields.length; i ++) {
                if (i != this.fields.length - 1) this.strKeys += this.fields[i].key +',';
                else this.strKeys += this.fields[i].key;
            }

            http
                .post(`${serverUri}/settlement/getdata`, {
                    start: this.startDate,
                    end: this.endDate,
                    table: this.selected,
                    strKeys: this.strKeys,
                    companyId: getUserData().company_id
                }, {
                    headers: {
                        'Authorization': `Bearer ${getUserData().accessToken}`,
                        'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    
                    const datas = res.data.map((item) => {
                        return {
                            ...item,
                            date: new Date(item.date).toLocaleDateString(),
                        };
                    });
                    this.datas = datas;

                    if (this.selected.title == "fuel_purchases") {
                        for (let i = 0; i < datas.length; i ++) {
                            sumQuantity += datas[i].qty;
                            sumAmount += datas[i].pur_amt;
                        }

                        sumRow.push({ 
                            year_num: 'Sum',
                            Week: '',
                            Date: '',
                            Vehicle: '',
                            TruckStop: '',
                            City: '',
                            State: '',
                            qty: sumQuantity.toLocaleString(),
                            pur_amt: sumAmount.toLocaleString()
                        });

                        this.items = [...sumRow, ...datas];
                    } else if (this.selected.title == "linehaul_trips") {
                        for (let i = 0; i < datas.length; i ++) {
                            sumQuantity += datas[i].miles_qty;
                            sumAmount += datas[i].daily_gross_amt;
                        }

                        sumRow.push({ 
                            date: 'Sum',
                            TripId: '',
                            Vehicle: '',
                            Orgn: '',
                            Dest: '',
                            miles_qty: sumQuantity.toLocaleString(),
                            Vmr: '',
                            MileagePlus: '',
                            fuel: '',
                            TotalRate: '',
                            $Amt: '',
                            DH: '',
                            FlatRate: '',
                            daily_gross_amt: "$" + sumAmount.toLocaleString(),
                            driver_1: '',
                            driver_2: ''
                        });

                        this.items = [...sumRow, ...datas];
                    } else if (this.selected.title == "tractor_repairs_misc") {
                        for (let i = 0; i < datas.length; i ++) {
                            sumAmount += Math.abs(datas[i].repair_misc_amt);
                        }

                        sumRow.push({
                            year_num: 'Sum',
                            Week: '',
                            Date: '',
                            Vehicle: '',
                            TruckStop: '',
                            City: '',
                            State: '',
                            repair_misc_amt: sumAmount.toLocaleString()
                        });

                        this.items = [...sumRow, ...datas];
                    } else {
                        this.items = datas;
                    }
                }).
                catch((error) => {
                    // Display a toast message or handle the error in some way
                    let errorMessage = 'Failed to fetch data. Please try again.';
                    if (error.response && error.response.status === 403) {
                        errorMessage = 'Login Data Access Expired. Log out and log back in'; // or 'Forbidden'
                    }
                    this.showToast('error', 'Failure caused', errorMessage);
                    
                });

            
        },
        setField() {
            if (this.selected.title === "fuel_purchases") {
                this.fields = [
                    {
                        key: "year_num",
                        sortable: true,
                        label: "Year",
                    },
                    {
                        key: "week_num",
                        sortable: true,
                        label: "Week",
                    },
                    {
                        key: "date",
                        sortable: true,
                        label: "Date",
                    },
                    {
                        key: "vehicle",
                        sortable: true,
                        label: "Vehicle",
                    },
                    {
                        key: "truck_stop",
                        sortable: true,
                        label: "Truck Stop",
                    },
                    {
                        key: "city",
                        sortable: true,
                        label: "City",
                    },
                    {
                        key: "state",
                        sortable: true,
                        label: "State",
                    },
                    {
                        key: "qty",
                        sortable: true,
                        label: "Quantity",
                    },
                    {
                        key: "pur_amt",
                        sortable: true,
                        label: "Amount",
                    },
                ];
            } else if (this.selected.title === "linehaul_spots") {
                this.fields = [
                    {
                        key: "year_num",
                        sortable: true,
                        label: "Year",
                    },
                    {
                        key: "week_num",
                        sortable: true,
                        label: "Week",
                    },
                    {
                        key: "date",
                        sortable: true,
                        label: "Date",
                    },
                    {
                        key: "vehicle",
                        sortable: true,
                        label: "Vehicle",
                    },
                    {
                        key: "avr_num",
                        sortable: true,
                        label: "Avr Num",
                    },
                    {
                        key: "trip_id",
                        sortable: true,
                        label: "Trip ID",
                    },
                    {
                        key: "spot_name",
                        sortable: true,
                        label: "Spot Name",
                    },
                    {
                        key: "site_name",
                        sortable: true,
                        label: "Site Name",
                    },
                    {
                        key: "miles",
                        sortable: true,
                        label: "Miles",
                    },
                    {
                        key: "fuel_rate",
                        sortable: true,
                        label: "Fuel Rate",
                    },
                    {
                        key: "fuel_amt",
                        sortable: true,
                        label: "Fuel Amt",
                    },
                    {
                        key: "spot_amt",
                        sortable: true,
                        label: "Spot Amt",
                    },
                    {
                        key: "live_pkgs",
                        sortable: true,
                        label: "Live Pkgs",
                    },
                    {
                        key: "live_amt",
                        sortable: true,
                        label: "Live Amt",
                    },
                    {
                        key: "mileage_rate",
                        sortable: true,
                        label: "Mileage Rate",
                    },
                    {
                        key: "mileage_amt",
                        sortable: true,
                        label: "Mileage Amt",
                    },
                    {
                        key: "amt",
                        sortable: true,
                        label: "AMT",
                    },
                    {
                        key: "driver_1",
                        sortable: true,
                        label: "Driver 1",
                    },
                    {
                        key: "driver_2",
                        sortable: true,
                        label: "Driver 2",
                    },
                ];
            } else if (this.selected.title === "other_settlement_adjustments") {
                this.fields = [
                    {
                        key: "year_num",
                        sortable: true,
                        label: "Year",
                    },
                    {
                        key: "week_num",
                        sortable: true,
                        label: "Week",
                    },
                    {
                        key: "date",
                        sortable: true,
                        label: "Date",
                    },
                    {
                        key: "type",
                        sortable: true,
                        label: "Type",
                    },
                    {
                        key: "description",
                        sortable: true,
                        label: "Description",
                    },
                    {
                        key: "amt",
                        sortable: true,
                        label: "Amount",
                    },
                ];
            } else if (this.selected.title === "linehaul_trips") {
                this.fields = [
                    {
                        key: "date",
                        sortable: true,
                        label: "Date",
                    },
                    {
                        key: "trip_id",
                        sortable: true,
                        label: "Trip ID",
                    },
                    {
                        key: "vehicle",
                        sortable: true,
                        label: "Vehicle",
                    },
                    {
                        key: "leg_org",
                        sortable: true,
                        label: "ORGN",
                    },
                    {
                        key: "leg_dest",
                        sortable: true,
                        label: "DEST",
                    },
                    {
                        key: "miles_qty",
                        sortable: true,
                        label: "QTY",
                    },
                    {
                        key: "vmr_rate",
                        sortable: true,
                        label: "VMR",
                    },
                    {
                        key: "mileage_plus",
                        sortable: true,
                        label: "Mileage Plus",
                    },
                    {
                        key: "fuel",
                        sortable: true,
                        label: "Fuel",
                    },
                    {
                        key: "total_rate",
                        sortable: true,
                        label: "Total Rate",
                    },
                    {
                        key: "amt_1",
                        sortable: true,
                        label: "$ AMT",
                    },
                    {
                        key: "d_and_h",
                        sortable: true,
                        label: "D&H",
                    },
                    {
                        key: "flat_rate",
                        sortable: true,
                        label: "Flat Rate",
                    },
                    {
                        key: "daily_gross_amt",
                        sortable: true,
                        label: "Daily Gross $ Amount",
                    },
                    {
                        key: "driver_1",
                        sortable: true,
                        label: "Driver 1",
                    },
                    {
                        key: "driver_2",
                        sortable: true,
                        label: "Driver 2",
                    },
                ];
            } else if (this.selected.title === "tractor_repairs_misc") {
                this.fields = [
                    {
                        key: "year_num",
                        sortable: true,
                        label: "Year",
                    },
                    {
                        key: "week_num",
                        sortable: true,
                        label: "Week",
                    },
                    {
                        key: "date",
                        sortable: true,
                        label: "Date",
                    },
                    {
                        key: "vehicle",
                        sortable: true,
                        label: "Vehicle",
                    },
                    {
                        key: "truck_stop",
                        sortable: true,
                        label: "Truck Stop",
                    },
                    {
                        key: "city",
                        sortable: true,
                        label: "City",
                    },
                    {
                        key: "state",
                        sortable: true,
                        label: "State",
                    },
                    {
                        key: "repair_misc_amt",
                        sortable: true,
                        label: "Amount",
                    },
                ];
            } else if (this.selected.title === "linehaul_drivers") {
                this.fields = [
                    {
                        key: "driver_id",
                        sortable: true,
                        label: "Driver ID",
                    },
                    {
                        key: "driver_name",
                        sortable: true,
                        label: "Name",
                    },
                    {
                        key: "email",
                        sortable: true,
                        label: "Email",
                    },
                    {
                        key: "phone",
                        sortable: true,
                        label: "Phone",
                    },
                    {
                        key: "license",
                        sortable: true,
                        label: "License",
                    },
                    {
                        key: "address",
                        sortable: true,
                        label: "Address",
                    },
                    {
                        key: "price_per_mile",
                        sortable: true,
                        label: "Price Per Mile",
                    },
                ];
            }
        },
        csvExport() {
            const data = this.items;
            const fileName = "settlements";
            const exportType = exportFromJSON.types.csv;

            if (data) exportFromJSON({ data, fileName, exportType });
        },
        exportPDF: function() {
            this.$refs.contentToExport.save();
        },
        toggleChatModal() {
            
            const chatData = {
                reportType: this.selected.title,
                dateRange: `${this.startDate} to ${this.endDate}`,
                settlementData: this.datas, // Pass the datas variable to ChatGPT
            };
            console.log(chatData);
            if(this.$refs.chatModal){
                this.$refs.chatModal.openChatModal(chatData);
            }
            //this.showChatModal = show;
        },
    },
    
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.hiddenContainer {
    position: absolute;
    left: -10000px;
    top: 0;
}
</style>
